<div class="content-wrapper">
    @if (loading) {
        <div [@fadeOutIn] class="loading">
            <img draggable="false" class="logo"  src="../assets/Other/Logo-white.svg"  alt="loading">
            <img draggable="false" loading='lazy' class="circle" src="../assets/Other/Loading-circle.webp"  alt="loading">
        </div>
    }

    <!-- Menu -->
    @if (menuShown) {
        <section [@menu]  class="menu">
            <main class="main">
                <div class="top">
                    <div class="goBack" (click)="menuToggl()">
                        <img draggable="false" class="img" src="../assets/Other/chevron-white.svg" alt="back">
                        <div class="text">Back</div>
                    </div>
                    <span class="top__el">Menu</span>
                </div>
                <div class="list">
                    <div class="list__el">
                        <span (click)="goToQuote()"  id="getQuote" class="text">Get A Quote</span>
                    </div>
                    <p  target="blank" class="list__el">
                        <a tabindex="-1" href="https://app.haulex.com/#/login" title="HaulEx site login" class="text">Login</a>
                    </p>
                </div>
                <ul  class="sub-list" (click)="goToSection($event)">
                    <li class="sub-list__el" id="stats">Our Focus</li>
                    <li class="sub-list__el" id="map">Service Locations</li>
                    <li class="sub-list__el" id="service">Service Options</li>
                    <li class="sub-list__el" id="solutions">Solutions</li>
                    <li class="sub-list__el" id="careers">Careers</li>
                    <li class="sub-list__el" id="faqs">FAQ`S</li>
                </ul>
            </main>
            <a tabindex="-1" href="tel:360-539-8600" title="HaulEx app login" class="number">360-539-8600</a>
    
        </section>
    }
    
    <!-- Header -->
    <div class="section-wrapper" [@header]="selectedSection" >
        <header  [@headerElements]="selectedSection" (@headerElements.done)="animationFinished($event)" id="header" class="header">
            
            <main class="main">
                <div class="content-wrapperr">
                    <nav  class="nav">
                        <img draggable="false" loading="lazy" class="nav__logo" src="../assets/Other/DD-white.svg" alt="Diesel Dispatch">
                        <div class="nav__sidebar">
                            @if (['desktop','macxl','mac','ipadxl','tabletxl'].includes(type)) {
                                <a tabindex="-1" class="number" title="help phone number" href="tel:360-539-8600">360-539-8600</a>
                            }
                            @if (type !== 'mobile') {
                                <img draggable="false" class="img" src="../assets/Other/Logo-white.svg" alt="HaulEx logo">
                                <a tabindex="-1" class="text" href="https://app.haulex.com/#/login" title="HaulEx app login">Login</a>
                                <div class="white-line"></div>
                            }
                            <img draggable="false" [ngStyle]="{ marginRight: type !== 'mobile' ? '0px' : '10px' }" (click)="menuToggl()" class="burger" src="../assets/Other/burger-white.svg" alt="Menu">
                        </div>
                    </nav>
                    <div class="main-text">
                        <div class="title">
                            <!-- Each word divided to separated p to provide step animation to each word with different interval appearing -->
                            @if(['mobile','tablet','ipad'].includes(type)){
                                <div class="cont">
                                    <p>Y</p><p>o</p><p>u</p><p>r</p>&nbsp;<p>A</p><p>u</p><p>t</p><p>o</p>
                                </div>
                                <div class="cont">
                                    <p>T</p><p>r</p><p>a</p><p>n</p><p>s</p><p>p</p><p>o</p><p>r</p><p>t</p>&nbsp;<p>E</p><p>x</p><p>p</p><p>e</p><p>r</p><p>t</p>
                                </div>
                            }
                            @if(['tabletxl','ipadxl'].includes(type)){
                                <div class="cont">
                                    <p>Y</p><p>o</p><p>u</p><p>r</p>&nbsp;<p>A</p><p>u</p><p>t</p><p>o</p>&nbsp;<p>T</p><p>r</p><p>a</p><p>n</p><p>s</p><p>p</p><p>o</p><p>r</p><p>t</p>&nbsp;<p>E</p><p>x</p><p>p</p><p>e</p><p>r</p><p>t</p>
                                </div>
                            }
                            @if(['mac','macxl','desktop'].includes(type)){
                                <div class="cont">
                                    <p>Y</p><p>o</p><p>u</p><p>r</p>&nbsp;<p>A</p><p>u</p><p>t</p><p>o</p>
                                </div>
                                <div class="cont">
                                    <p>T</p><p>r</p><p>a</p><p>n</p><p>s</p><p>p</p><p>o</p><p>r</p><p>t</p>
                                </div>
                                <div class="cont">
                                    <p>E</p><p>x</p><p>p</p><p>e</p><p>r</p><p>t</p>
                                </div>
                            }
                        </div>
                        
                        <!-- @if (['desktop','macxl','mac'].includes(type) && !linkLoaded) {
                            <img draggable="false" #img src="../assets/Other/quote-form-thumbnail.webp" class="getAQuote thumbnail absolute right-0">
                        } -->
                        @if (['desktop','macxl','mac'].includes(type) && linkLoaded) {
                            <iframe class="getAQuote haulex-form" 
                            title="Get A Quote"
                            #iframeForm
                            style="border-width: 0;" 
                            data-hj-allow-iframe="true"
                            data-referenceid="5Wd_O7HUukC" 
                            data-source="DieselDispatch" 
                            data-onsuccess="thankyou" 
                            [src]="link"
                            [attr.data-url]="dataUrl"
                            data-gtm-yt-inspected-7="true">
                            </iframe>
                        }
                        
                    </div>
                    <div class="bottom">
                        <button tabindex="-1" (click)="goToQuote()" class="bottom__button quote">Get a quote</button>
                        <button tabindex="-1" class="bottom__button track">Track my vehicle</button>
                    </div>
                </div>
            </main>
    
            <div class="gradient"></div> 
    
        </header>
    </div>
    
    <!-- Stats -->
    <div class="section-wrapper" [@stats]="selectedSection">
        <section [attr.data-disabled]="selectedSections.includes('stats') ? true : false" [@statsElements]="{value: selectedSection, params: {
            rotation: arrowRotation === 'horizontal' ? 'translateX(-30%)' : 'translateY(-30%)'
        }}" (@statsElements.done)="animationFinished($event)" id="stats" class="stats">
            <main class="main">
                @if (['mobile','tablet','ipad'].includes(type)) {
                    <div (click)="goToQuote()"  id="getQuote"  class="getAQuote-wrapper getA">
                        <img draggable="false" loading='lazy' class="circle" src="../assets/Other/GetAQuote-circle.svg" alt="GetAQuote">
                        <img draggable="false" loading='lazy' class="arrow" src="../assets/Other/short-arrow.svg" alt="arrow">
                    </div>
                }
                
                <div class="title-wrapper">
                    <h2 class="text">
                        <p>Since 2009,</p>
                        <p>our Focus</p> 
                        <p>is still the same.</p>  
                    </h2>
                    <h2 class="text">
                        <p>Simply provide the</p>
                        <p>best auto transport</p>
                        <p>experience to our</p>
                        <p>customer!</p>
                    </h2>
                </div>
                <div class="image-down">
                    <!-- <div class="arrow-conteiner"> -->
                        <picture class="img">
                            <source srcset="
                            ../assets/Other/slide-down2.svg,
                            " media="(min-width: 1280px)" />
                            <source srcset="
                            ../assets/Other/slide-down3.svg,
                            " media="(min-width: 1920px)" 
                            />
                            <img draggable="false" class="img_" src="../assets/Other/slide-down.svg" alt="">
                        </picture>
                    <!-- </div> -->
                </div>
                @if (type === 'mobile' || type === '') {
                    <div class="swiper-stats swiper">
                        <div class="stats-tape swiper-wrapper">
                            <div class="stat swiper-slide">
                                <h3 class="title">2475+</h3>
                                <p class="sub-text">Cars Moved <br> Each Month</p>
                            </div>
                            <div class="stat swiper-slide">
                                <h3 class="title">150+</h3>
                                <p class="sub-text">Hot Routes <br> Serviced Weekly</p>
                            </div>
                            <div class="stat swiper-slide">
                                <h3 class="title">55+</h3>
                                <p class="sub-text">Trucks Under <br> Our Family</p>
                            </div>
                            <div class="stat swiper-slide">
                                <h3 class="title">1269+</h3>
                                <p class="sub-text">Carriers In <br> Our Network</p>
                            </div>
                            <div class="stat swiper-slide">
                                <h3 class="title">100%</h3>
                                <p class="sub-text">Compliant <br> &  Insured</p>
                            </div>
                        </div>
                    <div class="arrow-toggls">
                        <img draggable="false" class="img swiper-button-prev" src="../assets/Other/chevron-white.svg" alt="left">
                        <img draggable="false" class="img swiper-button-next" src="../assets/Other/chevron-white.svg" alt="right">
                    </div>
                </div>
                }
                @if (type !== 'mobile' && type !== '') {
                    <div class="stats">
                        @if (!['mobile','tablet','ipad'].includes(type)) {
                            <div (click)="goToQuote()"  id="getQuote" class="getAQuote-wrapper getA">
                                <img draggable="false" loading='lazy' class="circle" src="../assets/Other/GetAQuote-circle.svg" alt="GetAQuote">
                                <img draggable="false" loading='lazy' class="arrow" src="../assets/Other/short-arrow.svg" alt="arrow">
                            </div>
                        }
                        
                        <div class="stat first">
                            <h3 class="title">2475+</h3>
                            <p class="sub-text">Cars Moved Each Month</p>
                        </div>
                        <div class="stat second">
                            <h3 class="title">150+</h3>
                            <p class="sub-text">Hot Routes Serviced Weekly</p>
                        </div>
                        <div class="stat third">
                            <h3 class="title">55+</h3>
                            <p class="sub-text">Trucks Under Our Family</p>
                        </div>
                        <div class="stat fourth">
                            <h3 class="title">1269+</h3>
                            <p class="sub-text">Carriers In Our Network</p>
                        </div>
                        <div class="stat fifth">
                            <h3 class="title">100%</h3>
                            <p class="sub-text">Compliant & Insured</p>
                        </div>
                    </div>
                }
                
            </main>
        </section>
    </div>
    
    <!-- Map -->
    <div class="section-wrapper" [@map]="selectedSection">
    <section [attr.data-disabled]="selectedSections.includes('map') ? true : false" [@mapElements]="selectedSection" (@mapElements.done)="animationFinished($event)" id="map" class="map">
        <main class="main">
            <h2 class="title">Real Time Pickups & Deliveries 2023</h2>
            <div class="map-view-conteiner">
                <div class="gradient-overlay1"></div>
                
                <div id="map-wrapper" class="map-wrapper" [ngStyle]="{ overflowX: videoLoading ? 'hidden' : 'auto' }">
                    
                    <div #videoConteiner id="video-conteiner" class="video-conteiner">
                        <div class="gradient-overlay3"></div>
                        @if (!supportsVideo) {
                        <img draggable="false" #map id="video" src="../assets/Map/Map-black.mp4" class="map-video"  alt="">
                        }

                        <!--  -->
                        @if (supportsVideo) {
                            <video id="video" (waiting)="videoWaiting()" (playing)="videoPlaying()" poster="../assets/Map/Map-thumbnail.webp" class="map-video" preload="yes" [controls]="!touchedOnce" #map [defaultMuted]="true" muted playsinline [playsInline]="true">
                                <source class="video_" src="../assets/Map/Map.mov" type="video/mp4">
                                <source class="video_" src="../assets/Map/Map.mp4" type="video/mp4">
                                <source class="video_" src="../assets/Map/Map.webm" type="video/webm">
                                <source class="video_" src="../assets/Map/Map.mov" type="video/mov">
                                <p class="text-[30px] text-red font-poppins z-[50] absolute top-[50%] left-[50%]">Your browser doesn`t support video tag</p>
                            </video>
                        }
                        @if (videoLoading) {
                            <div [@fadeOutIn] class="loading video">
                                <img draggable="false" class="logo"  src="../assets/Other/Logo-white.svg"  alt="loading">
                                <img draggable="false" loading='lazy' class="circle" src="../assets/Other/Loading-circle.webp"  alt="loading">
                            </div>
                        }
                        
                        <div class="gradient-overlay4"></div>
                    </div>
                </div>
                <div class="gradient-overlay2"></div>
                
            </div>
            @if ((!(['desktop'].includes(type))) && showArrow) {
                <div [@mapArrow] (click)="showNextSection()" class="arrow-down">
                    <img draggable="false" src="../assets/Map/arrow-down.svg" alt="NEXT">
                </div>
            }
        </main>
    </section>
    </div>

    <!-- Service -->
    <div class="section-wrapper" [@service]="selectedSection">
        <section [attr.data-disabled]="selectedSections.includes('service') ? true : false" [@serviceElements]='selectedSection' (@serviceElements.done)="animationFinished($event)" id="service" class="service">
            <main class="main">
                <div class="tape-conteiner" id="tape-conteiner">
                        <div class="title-conteiner">
                        @if(type === 'mobile'){
                            @if(
                                [3,4,5,0].includes(serviceIndex)
                            ){
                                <h3 [@serviceFadeOutIn] class="title">Service Options</h3>
                            }
                            @if(
                                [1,2,6].includes(serviceIndex)
                            ){
                                <h3 [@serviceFadeOutIn] class="title">Transport Types</h3>
                            }
                        }
                        @if(['tablet','ipad'].includes(type)){
                            @if(
                                [2,3,4,5,9].includes(serviceIndex)
                            ){
                                <h3 [@serviceFadeOutIn] class="title">Service Options</h3>
                            }
                            @if(
                                [0,1].includes(serviceIndex)
                            ){
                                <h3 [@serviceFadeOutIn] class="title">Transport Types</h3>
                            }
                        }

                        </div>
                        @if(type ==='mobile'){
                            <div [@mobileServiceToggl]="{
                                value:serviceIndex
                            }" (@mobileServiceToggl.start)="serviceAnimStart($event)" (@mobileServiceToggl.done)="serviceAnimFinish($event)" class="types-wrapper">
                                <div id="types-conteiner" class="types-conteiner">
                                    <!-- <div class="card-tape-wrapper"> -->
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Service Options</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <img draggable="false" class="img" src="../assets/service/expidited-service.svg" alt="Open">
                                                            <h4 class="heading">Expidited</h4>
                                                            <div class="line"></div>
                                                            <p class="text">
                                                                You can choose expedited service option when you have urgent, time sensitive schedules. Your shipment is prioritized to the front of the line with team drivers upon availability. This allows your vehicle to be delivered faster then industry standard without jeopardizing the safety of your vehicle or drivers.
                                                            </p>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Transport Types</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <img draggable="false" class="img" src="../assets/service/open-service.svg" alt="Open">
                                                            <h4 class="heading">Open Auto<br> Transport</h4>
                                                            <div class="line"></div>
                                                            <p class="text">
                                                                Our open auto transport fleet consists of trailers open to weather elements creating the perfect blend of affordability, flexibility of shipping vehicles of all shapes & sizes, all while arriving safely & on time to it's destination.
                                                            </p>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <!-- <h3 class="title">Transport Types</h3> -->
                                            <div class="card">
                                                <main class="card-body">
                                                    <div class="body-wrapper">
                                                        <img draggable="false" class="img" src="../assets/service/enclosed-service.svg" alt="Enclosed">
                                                        <h4 class="heading">Enclosed Auto<br> Transport</h4>
                                                        <div class="line"></div>
                                                        <p class="text">
                                                            Our enclosed auto transport fleet consists of meticulously maintained, climate-controlled trailers designed to shield your vehicle from the elements and road debris. This level of protection ensures that your prized possession arrives at its destination in the same pristine condition it left.
                                                        </p>
                                                    </div>
                                                </main>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Service Options</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <img draggable="false" class="img" src="../assets/service/flexible-service.svg" alt="Open">
                                                            <h4 class="heading">Flexible prime<br> Expedited</h4>
                                                            <div class="line"></div>
                                                            <p class="text">
                                                                Choosing Flex is a standby service just like on the airlines, only available with another shipments cancelation or delay. Most affordable, but you don't have the luxury of having your shipment scheduled ahead of time, more last minute notice & not always available.
                                                            </p>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Service Options</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <img draggable="false" class="img" src="../assets/service/prime-service.svg" alt="Open">
                                                            <h4 class="heading">Prime</h4>
                                                            <img draggable="false" class="popular-img" src="../assets/faqs/popular.webp" alt="popular">
                                                            <div class="line"></div>
                                                            <p class="text">
                                                                Prime service option is our featured and most popular transport service. Prime service is the  perfect blend of affordability, reliability and efficiency of time ensuring your vehicle arrives safely & on time to its destination.
                                                            </p>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Service Options</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <img draggable="false" class="img" src="../assets/service/expidited-service.svg" alt="Open">
                                                            <h4 class="heading">Expidited</h4>
                                                            <div class="line"></div>
                                                            <p class="text">
                                                                You can choose expedited service option when you have urgent, time sensitive schedules. Your shipment is prioritized to the front of the line with team drivers upon availability. This allows your vehicle to be delivered faster then industry standard without jeopardizing the safety of your vehicle or drivers.
                                                            </p>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Transport Types</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <img draggable="false" class="img" src="../assets/service/open-service.svg" alt="Open">
                                                            <h4 class="heading">Open Auto<br> Transport</h4>
                                                            <div class="line"></div>
                                                            <p class="text">
                                                                Our open auto transport fleet consists of trailers open to weather elements creating the perfect blend of affordability, flexibility of shipping vehicles of all shapes & sizes, all while arriving safely & on time to it's destination.
                                                            </p>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                    <!-- </div> -->
                                </div>
                                
                            </div>
                        }
                        
                        @if (type !== 'mobile') {
                            <div class="types-wrapper" [@desktopServiceToggl]="{
                                value:serviceIndex,
                                params: {
                                    first: serviceOptions.positions.first,
                                    second: serviceOptions.positions.second,
                                    third: serviceOptions.positions.third,
                                    last: serviceOptions.positions.last,
                                    firstShake: serviceOptions.positions.firstShake,
                                    lastShake: serviceOptions.positions.lastShake,
                                    lastPlace: serviceOptions.lastServiceSection
                                },
                            }" #serviceSection (@desktopServiceToggl.start)="serviceAnimStart($event)" (@desktopServiceToggl.done)="serviceAnimFinish($event)">
                                @if(['tabletxl','ipadxl','mac','macxl','desktop'].includes(type)){
                                    <div class="title-conteiner">
                                        <h3 class="title">Transport Types</h3>
                                        <h3 class="title">Service Options</h3>
                                    </div>
                                }
                                <div id="types-conteiner" class="types-conteiner">
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Transport Types</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <img draggable="false" class="img" src="../assets/service/open-service.svg" alt="Open">
                                                            <h4 class="heading">Open Auto<br> Transport</h4>
                                                            <div class="line"></div>
                                                            <p class="text">
                                                                Our open auto transport fleet consists of trailers open to weather elements creating the perfect blend of affordability, flexibility of shipping vehicles of all shapes & sizes, all while arriving safely & on time to it's destination.
                                                            </p>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card">
                                                <main class="card-body">
                                                    <div class="body-wrapper">
                                                        <img draggable="false" class="img" src="../assets/service/enclosed-service.svg" alt="Enclosed">
                                                        <h4 class="heading">Enclosed Auto<br> Transport</h4>
                                                        <div class="line"></div>
                                                        <p class="text">
                                                            Our enclosed auto transport fleet consists of meticulously maintained, climate-controlled trailers designed to shield your vehicle from the elements and road debris. This level of protection ensures that your prized possession arrives at its destination in the same pristine condition it left.
                                                        </p>
                                                    </div>
                                                </main>
                                            </div>
                                        </div>
                                        <div class="arrow-wrapper">
                                            <div class="arrow-conteiner">
                                                <img draggable="false" class="arrow" src="../assets/service/shake-arrow.svg" alt="">
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Service Options</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <img draggable="false" class="img" src="../assets/service/flexible-service.svg" alt="Open">
                                                            <h4 class="heading">Flexible prime<br> Expedited</h4>
                                                            <div class="line"></div>
                                                            <p class="text">
                                                                Choosing Flex is a standby service just like on the airlines, only available with another shipments cancelation or delay. Most affordable, but you don't have the luxury of having your shipment scheduled ahead of time, more last minute notice & not always available.
                                                            </p>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Service Options</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <img draggable="false" class="img" src="../assets/service/prime-service.svg" alt="Open">
                                                            <h4 class="heading">Prime</h4>
                                                            <img draggable="false" class="popular-img" src="../assets/faqs/popular.webp" alt="popular">
                                                            <div class="line"></div>
                                                            <p class="text">
                                                                Prime service option is our featured and most popular transport service. Prime service is the  perfect blend of affordability, reliability and efficiency of time ensuring your vehicle arrives safely & on time to its destination.
                                                            </p>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-wrapper">
                                            <div class="card-title-wrapper">
                                                <!-- <h3 class="title">Service Options</h3> -->
                                                <div class="card">
                                                    <main class="card-body">
                                                        <div class="body-wrapper">
                                                            <img draggable="false" class="img" src="../assets/service/expidited-service.svg" alt="Open">
                                                            <h4 class="heading">Expidited</h4>
                                                            <div class="line"></div>
                                                            <p class="text">
                                                                You can choose expedited service option when you have urgent, time sensitive schedules. Your shipment is prioritized to the front of the line with team drivers upon availability. This allows your vehicle to be delivered faster then industry standard without jeopardizing the safety of your vehicle or drivers.
                                                            </p>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        }
                </div>

                <div class="toggl-arrows">
                    <div class="arrow-wrapper">
                        <button tabindex="-1" class="arrow" (click)="serviceTogglFunc(-1)" >
                            <img draggable="false" class="img" src="../assets/Other/long-arrow.svg" alt="left">
                        </button>

                        <button tabindex="-1" class="arrow right" (click)="serviceTogglFunc(1)">
                            <img draggable="false" class="img" src="../assets/Other/long-arrow.svg" alt="right">
                        </button>
                    </div>
                </div>

            
            </main>
            <div class="option-tape-conteiner">
                <div class="gradient"></div>
                <div class="options-wrapper">
                    <!-- Clones for endless tape animation -->
                    <h2>Service Options</h2>
                    <img draggable="false" src="../assets/Other/Logo-white.svg" alt="HaulEx">
                    <h2>Service Options</h2>
                    <img draggable="false" src="../assets/Other/Logo-white.svg" alt="HaulEx">
                    <h2>Service Options</h2>
                    <img draggable="false" src="../assets/Other/Logo-white.svg" alt="HaulEx">
                    @if(['tabletxl','ipadxl','mac'].includes(type)){
                        <h2>Service Options</h2>
                        <img draggable="false" src="../assets/Other/Logo-white.svg" alt="HaulEx">
                        <h2>Service Options</h2>
                        <img draggable="false" src="../assets/Other/Logo-white.svg" alt="HaulEx">
                    }
                    @if(['macxl','desktop'].includes(type)){
                        <h2>Service Options</h2>
                        <img draggable="false" src="../assets/Other/Logo-white.svg" alt="HaulEx">
                        <h2>Service Options</h2>
                        <img draggable="false" src="../assets/Other/Logo-white.svg" alt="HaulEx">
                        <h2>Service Options</h2>
                        <img draggable="false" src="../assets/Other/Logo-white.svg" alt="HaulEx">
                        <h2>Service Options</h2>
                        <img draggable="false" src="../assets/Other/Logo-white.svg" alt="HaulEx">
                    }
                </div>
                <div class="gradient"></div>
            </div>
        </section>
    </div>

    <!-- Solutions -->
    <div class="section-wrapper" [@solutions]="selectedSection">
        <section #solutions [attr.data-disabled]="selectedSections.includes('solutions') ? true : false" [@solutionsElements]="selectedSection" (@solutionsElements.done)="animationFinished($event)" id="solutions" class="solutions">
            <main class="main">
                <h2 class="title">We deliver solutions@if(['ipad','tablet','mobile'].includes(type)){<br>} for every customer</h2>
                <!-- <button tabindex="-1" (click)="prepend()" class="text-white text-[42px] absolute top-0 left-[50%] z-[1000]">PREPEND ELEMENT</button> -->
                <div class="swipe-gallery-container">
                    <div class="text-gallery-container">
                        <div #verticalLine class="vertical-line"></div>
                        <ul #cardsContainer class="text-container" id="text-container" [ngStyle]="{
                            'cursor': isSolutionDrag ? 'grabbing' : 'grab'
                        }">
                            <li data-id="9" class="text-wrapper">
                                <div class="link-container">
                                    <div class="text text-moveable">
                                            <span>Classic Car</span><span>Shipping</span>
                                    </div>
                                    <div class="text text-invisible">Classic Car Shipping</div>
                                </div>
                            </li>
                            <li data-id="10" class="text-wrapper">
                                <div class="link-container">
                                    <div class="text text-moveable">
                                        <span>Alaska & Hawaii</span><span>Shipping</span>
                                    </div>
                                    <div class="text text-invisible">Alaska & Hawaii Shipping</div>
                                </div>
                            </li>
                            <li data-id="11" class="text-wrapper">
                                <div class="link-container">
                                    <div class="text text-moveable"><span>Rental car</span><span>Companies</span></div>
                                    <div class="text text-invisible">Rental car Companies</div>
                                </div>
                            </li>
                            <li data-id="1" class="text-wrapper">
                                <div class="link-container">
                                    <div class="text text-moveable">OEM`s</div>
                                    <div class="text text-invisible">OEM`s</div>
                                </div>
                            </li>
                            <li data-id="2" class="text-wrapper">
                                <div class="link-container">
                                    <div class="text text-moveable"><span>Relocating</span><span>Families</span></div>
                                    <div class="text text-invisible">Relocating Families</div>
                                </div>
                            </li>
                            <li data-id="3" class="text-wrapper">
                                <div class="link-container">
                                    <div class="text text-moveable">Dealerships</div>
                                    <div class="text text-invisible">Dealerships</div>
                                </div>
                            </li>
                            <li data-id="4" class="text-wrapper">
                                <div class="link-container">
                                    <div class="text text-moveable"><span>Auto</span><span>Shows</span></div>
                                    <div class="text text-invisible">Auto Shows</div>
                                </div>
                            </li>
                            <li data-id="5" class="text-wrapper">
                                <div class="link-container">
                                    <div class="text text-moveable">
                                        <span>Corporate</span><span>Relocations</span>
                                    </div>
                                    <div class="text text-invisible">Corporate Relocations</div>
                                </div>
                            </li>
                            <li data-id="6" class="text-wrapper">
                                <div class="link-container">
                                    <div class="text text-moveable"><span>Snowbird</span><span>Shipping</span></div>
                                    <div class="text text-invisible">Snowbird Shipping</div>
                                </div>
                            </li>
                            <li data-id="7" class="text-wrapper">
                                <div class="link-container">
                                    <div class="text text-moveable"><span>Military</span><span>Shipping</span></div>
                                    <div class="text text-invisible">Military Shipping</div>
                                </div>
                            </li>
                            <li data-id="8" class="text-wrapper">
                                <div class="link-container">
                                    <div class="text text-moveable"><span>College</span><span>Shipping</span></div>
                                    <div class="text text-invisible">College Shipping</div>
                                </div>
                            </li>
                        </ul>
                        <ul style="translate: 0px" class="text-container copy" id="text-container-copy">
                            <li data-id="9" class="text-wrapper">
                                <div class="link-container">
                                    <div class="text text-moveable"><span>Classic Car</span><span>Shipping</span></div>
                                    <div class="text text-invisible">Classic Car Shipping</div>
                                </div>
                            </li>
                            <li data-id="10" class="text-wrapper">
                                <div class="link-container">
                                    <div class="text text-moveable">
                                        <span>Alaska & Hawaii</span><span>Shipping</span>
                                    </div>
                                    <div class="text text-invisible">Alaska & Hawaii Shipping</div>
                                </div>
                            </li>
                            <li data-id="11" class="text-wrapper">
                                <div class="link-container">
                                    <div class="text text-moveable"><span>Rental car</span><span>Companies</span></div>
                                    <div class="text text-invisible">Rental car Companies</div>
                                </div>
                            </li>
                            <li data-id="1" class="text-wrapper">
                                <div class="link-container">
                                    <div class="text text-moveable">OEM`s</div>
                                    <div class="text text-invisible">OEM`s</div>
                                </div>
                            </li>
                            <li data-id="2" class="text-wrapper">
                                <div class="link-container">
                                    <div class="text text-moveable"><span>Relocating</span><span>Families</span></div>
                                    <div class="text text-invisible">Relocating Families</div>
                                </div>
                            </li>
                            <li data-id="3" class="text-wrapper">
                                <div class="link-container">
                                    <div class="text text-moveable">Dealerships</div>
                                    <div class="text text-invisible">Dealerships</div>
                                </div>
                            </li>
                            <li data-id="4" class="text-wrapper">
                                <div class="link-container">
                                    <div class="text text-moveable"><span>Auto</span><span>Shows</span></div>
                                    <div class="text text-invisible">Auto Shows</div>
                                </div>
                            </li>
                            <li data-id="5" class="text-wrapper">
                                <div class="link-container">
                                    <div class="text text-moveable">
                                        <span>Corporate</span><span>Relocations</span>
                                    </div>
                                    <div class="text text-invisible">Corporate Relocations</div>
                                </div>
                            </li>
                            <li data-id="6" class="text-wrapper">
                                <div class="link-container">
                                    <div class="text text-moveable"><span>Snowbird</span><span>Shipping</span></div>
                                    <div class="text text-invisible">Snowbird Shipping</div>
                                </div>
                            </li>
                            <li data-id="7" class="text-wrapper">
                                <div class="link-container">
                                    <div class="text text-moveable"><span>Military</span><span>Shipping</span></div>
                                    <div class="text text-invisible">Military Shipping</div>
                                </div>
                            </li>
                            <li data-id="8" class="text-wrapper">
                                <div class="link-container">
                                    <div class="text text-moveable"><span>College</span><span>Shipping</span></div>
                                    <div class="text text-invisible">College Shipping</div>
                                </div>
                            </li>
                        </ul>
                        <div class="image-container" id="image-container">
                            <div class="image-wrapper">

                                <div class="image">
                                    @for (image of solutionImageGallery; track image; let dataId = $index) {
                                        <!-- Image done with this way, because if programmly change them images are starting to downloading each change -->
                                        <img class="first" [attr.data-src]="solutionImageGallery[(dataId-1 === -1 ? 10 : dataId-1)]" 
                                        [ngClass]="{
                                            'unobserved': dataId+1 !== this.selectedSolution,
                                            'collapsed-side': this.isSolutionDrag
                                        }"
                                        alt="">
                                        <img [attr.data-src]="image"
                                        class="main"
                                        [ngClass]="{
                                            'unobserved': dataId+1 !== this.selectedSolution,
                                            'collapsed': this.isSolutionDrag
                                        }" alt="">
                                        <img class="last" [attr.data-src]="solutionImageGallery[dataId+1 === 11 ? 0 : dataId+1]"
                                        [ngClass]="{
                                            'unobserved': dataId+1 !== this.selectedSolution,
                                            'collapsed-side': this.isSolutionDrag
                                        }"
                                         alt="">
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="drag-hor-container">
                    <div class="cont">
                        <img draggable="false" src="../assets/solutions/drag-hand.svg" alt="Drag hand">
                        drag horizontal
                    </div>
                </div>
            </main>
        </section>
    </div>

    <!-- Careers -->
    <div class="section-wrapper" [@careers]="selectedSection">
    <section [attr.data-disabled]="selectedSections.includes('careers') ? true : false" [@careersElements]="selectedSection" (@careersElements.done)="animationFinished($event)" id="careers" class="careers">
        <div class="gradient gradient1"></div>
        <main class="main">
            <div class="content">
                <h2 class="title">Careers in auto @if(type ==='ipad'){<br>}transport</h2>
                <span class="sub-text">Join The Best Team In The Industry</span>
                <button tabindex="-1" class="button"
                routerLink="/cand-form"
                >Im interested</button>
            </div>
        </main>
        <div class="gradient gradient2"></div>
    </section>
    </div>

    <!-- Faqs -->
    <div class="section-wrapper" [@faqs]="selectedSection">
        <section [attr.data-disabled]="selectedSections.includes('faqs') ? true : false" [@faqsElements]='selectedSection' (@faqsElements.done)="animationFinished($event)" id="faqs" class="faqs">
            <main class="main">
                <div class="top">
                    <div class="text-wrapper">
                        <!-- <p class="text-image">
                            <img draggable="false" class="symbol" src="../assets/faqs/faq-symbol.svg" alt="">
                            <span>FAG`s: answers to questions. Contact us if necessary.</span>
                            <img draggable="false" class="symbol mirror" src="../assets/faqs/faq-symbol.svg" alt="">
                        </p> -->
                        <h2 class="title">faq`s</h2>
                    </div>
                    <div (click)="goToQuote()" id="getQuote" class="getAQuote-wrapper getA">
                        <img draggable="false" loading='lazy' class="circle" src="../assets/Other/GetAQuote-circle.svg" alt="GetAQuote">
                        <img draggable="false" loading='lazy' class="arrow" src="../assets/Other/short-arrow.svg" alt="arrow">
                    </div>
                </div>
                <ul class="faqs-list">
                    <!-- This version of faq`s is showing only on mobile -->
                    @if(['mobile'].includes(type)){
                        <div class="list-conteiner">
                            @if (showFaqCard('1')) {
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqPopH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="1"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('1')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('4')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="4"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('4')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('7')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="7"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('7')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('ten')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="10"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('10')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('2')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="2"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('2')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('5')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="5"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('5')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('8')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="8"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('8')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('elev')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="11"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('11')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('3')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="3"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('3')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('6')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="6"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('6')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                [minText]="true"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('9')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="9"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('9')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                [minText]="true"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('twel')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="12"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('12')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                [minText]="true"
                                >
                                </faqs-card>
                            }
                        </div>
                    }
                    <!-- Tablet, Ipad -->
                    @if(['tablet','ipad'].includes(type)){
                        <div class="list-conteiner">
                            @if (showFaqCard('1')) {
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqPopH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="1"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('1')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('9')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="9"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('9')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            
                            @if(showFaqCard('elev')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="11"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('11')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('6')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="6"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('6')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('twel')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="12"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('12')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('7')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="7"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('7')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('8')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="8"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('8')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                [minText]="true"
                                >
                                </faqs-card>
                            }
                           
                            
                            @if(showFaqCard('ten')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="10"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('10')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                           
                            @if(showFaqCard('2')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="2"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('2')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('3')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="3"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('3')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('4')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="4"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('4')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('5')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="5"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('5')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                    }
                    <!-- Tabletxl,Ipadxl,mac,macxl,desktop -->
                    @if(!(['tablet','ipad','mobile']).includes(type)){
                        <div class="list-conteiner">
                            @if(showFaqCard('7')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="7"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('7')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            @if (showFaqCard('1')) {
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqPopH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="1"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('1')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                            
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('8')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="8"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('8')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                [minText]="true"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('elev')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="11"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('11')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            @if(showFaqCard('9')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqPopH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="9"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('9')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                [minText]="true"
                                >
                                </faqs-card>
                            }
                            @if(showFaqCard('twel')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="12"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('12')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            
                            
                            @if(showFaqCard('ten')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqPopH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="10"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('10')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            
                            @if(showFaqCard('2')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="2"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('2')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            
                            @if(showFaqCard('3')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="3"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('3')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            
                            @if(showFaqCard('4')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="4"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('4')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            
                            @if(showFaqCard('5')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="5"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('5')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                        <div class="list-conteiner">
                            
                            @if(showFaqCard('6')){
                                <faqs-card [@faqsCardFade] [type]="type"
                                [animH]="animH"
                                [minFaqPopH]="minFaqCardH"
                                [minFaqCardH]="minFaqCardH"
                                [faqNumber]="6"
                                [openedFaq]="openedFaq"
                                (togglCard)="togglFaqs('6')"
                                (faqsAnimFinished)="faqsAnimFinish($event)"
                                >
                                </faqs-card>
                            }
                        </div>
                    }
                </ul>
                <div class="bottom">
                    @if(faqSectionIndex !== maxFaqSecInd){
                        <button tabindex="-1" (click)="morelessFaqs(1)" class="more button">
                            More
                            <img draggable="false" class="img" src="../assets/Other/chevron-white.svg" alt="">
                        </button>
                    }
                    @if(faqSectionIndex !== 1){
                        <button tabindex="-1" (click)="morelessFaqs(-1)" class="less button" [ngClass]="{
                            'less-margin': maxFaqSecInd !== faqSectionIndex
                        }">
                            <img draggable="false" class="img" src="../assets/Other/chevron-white.svg" alt="">
                        </button>
                    }
                </div>
            </main>
        </section>
    </div>

    <!-- Footer -->
    <div class="section-wrapper" [@footer]="selectedSection">
    <footer [attr.data-disabled]="selectedSections.includes('footer') ? true : false" [@footerElements]="selectedSection" (@footerElements.done)="animationFinished($event)" id="footer" class="footer">
        <main class="main">
            <div class="first-wrapper">
                <img draggable="false" class="logo"  src="../assets/Other/DD-white.svg" alt="Diesel Dispatch logo">
                <div class="text-wrapper">
                    <h1 class="title">
                        Diesel dispatch Is Your Best Business Partner.
                    </h1>
                    <h2 class="sub-text">
                        Our focus Is simply to build the best auto transport experience for thousands of customers & drivers daily!
                    </h2>
                </div>
                <div class="email-wrapper">
                    <div class="email-conteiner">
                        <input tabindex="-1" autocomplete="off" (focus)="question.classList.remove('success');" #question [(ngModel)]="messageContent" type="text" name="questions" id="question-input"
                        placeholder="Say hello, or ask your question here..."
                        class="input"
                        >
                        <button tabindex="-1" (click)="postMessage()" class="button">
                            Send
                        </button>
                    </div>  
                </div>
            </div>
            <div class="second-wrapper">
                <div (click)="goToQuote()" id="getQuote" class="getAQuote-wrapper getA">
                    <img draggable="false" loading='lazy' class="circle" src="../assets/Other/GetAQuote-circle.svg" alt="GetAQuote">
                    <img draggable="false" loading='lazy' class="arrow" src="../assets/Other/short-arrow.svg" alt="arrow">
                </div>
                <div class="bottom">
                    <span class="license">
                        DieselDispatch © {{year}}
                    </span>
                    <!-- routerLink='/terms-of-use' -->
                    <!-- <span class="terms" >
                        Dashboard Terms of Use autotransport.com 
                    </span> -->
                    <div (click)="backToTop()" class="backtotop">
                        <img draggable="false" class="img" src="../assets/Other/trending-up.svg" alt="">
                        <span class="text">Back To The Top</span>
                    </div>
                </div>
            </div>
            
        </main>
    </footer>
    </div>
</div>
    