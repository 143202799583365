<div class="content-wrapper">
    <div class="main">
        @if (!iframeLoaded) {
            <div [@loading] class="loading getAQuote">
                <img draggable="false" class="logo" src="../assets/Other/Logo-black.svg"  alt="loading">
                <img draggable="false" class="circle" src="../assets/Other/Loading-circle-white.webp"  alt="loading">
            </div>
        }
        @if (linkLoaded) {
            <iframe
                #iframeForm
                (load)="iframeLoadedHandle()"
                class="candForm"
                data-referenceid="9dLOUjoYQNo"
                [src]="link" 
                frameborder="0"
                [attr.data-url]="dataUrl"
                data-hj-allow-iframe="true"
                >
            </iframe>
        }
    </div>
</div>
