@switch (faqNumber) {
    @case (1) {
        <li [@faqsCard] class="card" id="card-1" (click)="togglFaqs('1')">
            <div class="card-inner" (@faqsToggl.done)="faqsAnimFinish($event)" [@faqsToggl]="{value: openedFaq === '1' ? 'rotated' : 'common',
            params: {
                cardHeight: minFaqPopH,
                currHeight: animH || '*',
                time: animH ? '0.5s' : '0.8s'
            }
            }">
                <div class="front card-body">
                    <h3 class="top-line">
                        <span class="inner-text">Why should<br> i book with you?</span>
                        <img loading="eager"draggable="false" class="popular" src="../assets/faqs/popular.webp" alt="popular">
                        <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq1.svg" alt="">
                    </h3>
                    <p class="text">Moving over 2200 cars a month, receiving a 97% customer satisfaction rate has been all organically built brick by brick..</p>
                </div>
                <div  class="back h-fit card-body">
                    <div data-back class="popular-text">
                        <div>
                            <span>
                            <h4>For 15+ years our focus has always been about the customer experience!</h4>
                            <br>
                            Moving over 2200 cars a month, receiving a 97% customer satisfaction rate has been all organically built brick by brick, offering our customers the best pricing (guarantee) with the best drivers, on the newest equipment. Oh yea, we take our safety very seriously, in fact, our over the road safety scores, are the best in the industry. To add the cherry on the top customers can get an instant quote, book, pay & track vehicle via HaulEx.com Portal.
                            <br>
                            <br>
                            How is all this possible? A daily committed effort by every department to make sure our trucks always stay full, drive safe & deliver on time allowing us to bring win win situations, to everyone!
                            <br>
                            Everyone spends $$ on fancy ads selling hopes & dreams, but we've been focused, building this experience every day for 15 years & counting<br>
                            <br>
                            <h4>IMPORTANT!</h4>
                            <br>
                            For whatever reason you don't want to book with us, please allow us to vet the company you want to work with, we will vet them at no cost and obligation to you. There's lots of great competitors out there, but many who have "cool advertisements" who just want to get your credit card deposit and have no interest of providing you a great service. 
                            <br>
                            <br>
                            We simply want you to get the best auto transport experience with whoever you choose to work with!
                            </span>
                        </div>
                    </div>
                    <div class="popular-logo-wrapper logo-wrapper">
                        <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq1.svg" alt="">
                    </div>
                </div>
            </div>
            
        </li>
    }
    @case (2) {
        @if(['desktop','macxl'].includes(type)){
            <li [@faqsCard] class="card" id="card-6" (click)="togglFaqs('2')">
                <div class="card-inner" (@faqsToggl.done)="faqsAnimFinish($event)" [@faqsToggl]="openedFaq === '2' ? 'rotated' : 'common'">
                    <div class="front card-body">
                        <h3 class="top-line">
                            <span class="inner-text">What is the difference between<br> open shipping and enclosed shipping.</span>
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq2.svg" alt="">
                        </h3>
                        <p class="text">Open Shipping is when our vehicle transports on the back of a truck, without the wheels touching the ground..</p>
                    </div>
                    <div class="back card-body">
                        <div class="text">
                            <p class="-wrapper --wr-fl">
                                <span>
                                    <p>Open Shipping is when our vehicle transports on the back of a truck, without the wheels touching the ground and it is exposed to the open air/weather elements. Just like if you were driving down the road, except it is not getting the wear and tear on the vehicle. This is the preferred and cheaper method of vehicle shipping.</p>
                                    <p>Enclosed Shipping is when your vehicle transports in an Enclosed truck (all sides closed like a box) and it is NOT exposed to the weather elements. This tends to be about 40-50% more expensive.</p>
                                </span>
                            </p>
                        </div>
                        <div class="logo-wrapper --short-margin">
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq2.svg" alt="">
                        </div>
                    </div>
                </div>
            </li>
        }@else {
            <li [@faqsCard] class="card" id="card-6" (click)="togglFaqs('2')">
                <div class="card-inner" (@faqsToggl.done)="faqsAnimFinish($event)" [@faqsToggl]="{value: openedFaq === '2' ? 'rotated' : 'common',
                params: {
                    cardHeight: minFaqCardH,
                    currHeight: animH || '*',
                    time: animH ? '0.3s' : '0.5s'
                }
                }">
                    <div class="front card-body">
                        <h3 class="top-line">
                            <span class="inner-text">What is the difference between<br> open shipping and enclosed shipping.</span>
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq2.svg" alt="">
                        </h3>
                        <p class="text">Open Shipping is when our vehicle transports on the back of a truck, without the wheels touching the ground..</p>
                    </div>
                    <div  class="back h-fit card-body">
                        <div data-back class="double text"  [ngClass]="{
                            '--min-text': minText
                        }">
                            <p class="wrapper --wr-fl">
                                <span>
                                    Open Shipping is when our vehicle transports on the back of a truck, without the wheels touching the ground and it is exposed to the open air/weather elements. Just like if you were driving down the road, except it is not getting the wear and tear on the vehicle. This is the preferred and cheaper method of vehicle shipping.<br>
                                    <br>
                                    Enclosed Shipping is when your vehicle transports in an Enclosed truck (all sides closed like a box) and it is NOT exposed to the weather elements. This tends to be about 40-50% more expensive.
                                </span>
                            </p>
                        </div>
                        <div class="logo-wrapper">
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq2.svg" alt="">
                        </div>
                    </div>
                </div>
            </li>
        }
        
    }
    @case (3) {
        <li [@faqsCard] class="card" id="card-7" (click)="togglFaqs('3')">
            <div class="card-inner" (@faqsToggl.done)="faqsAnimFinish($event)" [@faqsToggl]="openedFaq === '3' ? 'rotated' : 'common'">
                <div class="front card-body">
                    <h3 class="top-line">
                        <span class="inner-text">how long is my quote<br> good for?</span>
                        <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq3.svg" alt="">
                    </h3>
                    <p class="text">Your quote is good for 7-10 days. Prices fluctuate all year long in this industry, so you will always want to get updated pricing..</p>
                </div>
                <div class="back card-body">
                    <div class="text">
                        <p class="-wrapper --wr-fl">
                            <span>
                                Your quote is good for 7-10 days. Prices fluctuate all year long in this industry, so you will always want to get updated pricing at least 7 days ahead of your first available date.
                            </span>
                        </p>
                    </div>
                    <div class="logo-wrapper --short-margin">
                        <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq3.svg" alt="">
                    </div>
                </div>
            </div>
        </li>
    }
    @case (4) {
        @if(['desktop','macxl'].includes(type)){
            <li [@faqsCard] class="card" id="card-8" (click)="togglFaqs('4')">
                <div class="card-inner" (@faqsToggl.done)="faqsAnimFinish($event)" [@faqsToggl]="openedFaq === '4' ? 'rotated' : 'common'">
                    <div class="front card-body">
                        <h3 class="top-line">
                            <span class="inner-text">can i put items in<br> my vehicle?</span>
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq4.svg" alt="">
                        </h3>
                        <p class="text">Personal items are not recommended nor are they insured during transport. The DOT does not allow personal items/household..</p>
                    </div>
                    <div class="back card-body">
                        <div class="text">
                            <p class="-wrapper --wr-fl">
                                <span>
                                    <p>Personal items are not recommended nor are they insured during transport. The DOT does not allow personal items/household goods to ship in vehicles, however, most transport companies will let you have a few personal items under 100 lbs. in the trunk of your vehicle and in the trunk only.</p>
                                    <p>Drivers have weight limits they cannot exceed by law as well and they are fined at every weight station if the truck is overweight, so most prefer not to transport anything extra.</p>
                                </span>
                            </p>
                        </div>
                        <div class="logo-wrapper --short-margin">
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq4.svg" alt="">
                        </div>
                    </div>
                </div>
            </li>
        }@else {
            <li [@faqsCard] class="card" id="card-8" (click)="togglFaqs('4')">
                <div class="card-inner" (@faqsToggl.done)="faqsAnimFinish($event)" [@faqsToggl]="{value: openedFaq === '4' ? 'rotated' : 'common',
                params: {
                    cardHeight: minFaqCardH,
                    currHeight: animH || '*',
                    time: animH ? '0.3s' : '0.5s'
                }
                }">
                    <div class="front card-body">
                        <h3 class="top-line">
                            <span class="inner-text">can i put items in<br> my vehicle?</span>
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq4.svg" alt="">
                        </h3>
                        <p class="text">Personal items are not recommended nor are they insured during transport. The DOT does not allow personal items/household..</p>
                    </div>
                    <div  class="back h-fit card-body">
                        <div data-back class="double text"  [ngClass]="{
                            '--min-text': minText
                        }">
                            <p class="wrapper --wr-fl">
                                <span>
                                    Personal items are not recommended nor are they insured during transport. The DOT does not allow personal items/household goods to ship in vehicles, however, most transport companies will let you have a few personal items under 100 lbs. in the trunk of your vehicle and in the trunk only.
                                    <br>
                                    <br>
                                    Drivers have weight limits they cannot exceed by law as well and they are fined at every weight station if the truck is overweight, so most prefer not to transport anything extra.
                                </span>
                            </p>
                        </div>
                        <div class="logo-wrapper">
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq4.svg" alt="">
                        </div>
                    </div>
                </div>
                
            </li>
        }
        
    }
    @case (5) {
        @if(['desktop','macxl'].includes(type)){
            <li [@faqsCard] class="card" id="card-9" (click)="togglFaqs('5')">
                <div class="card-inner" (@faqsToggl.done)="faqsAnimFinish($event)" [@faqsToggl]="openedFaq === '5' ? 'rotated' : 'common'">
                    <div class="front card-body">
                        <h3 class="top-line">
                            <span class="inner-text">How long does it take to<br> ship a vehicle?</span>
                            <img loading="eager" draggable="false" class="sub-logo" src="../assets/faqs/faq5.svg" alt="">
                        </h3>
                        <p class="text">Shipping time frames vary on a case-by-case basis. A good rule of thumb, calculate how many miles your trip..</p>
                    </div>
                    <div class="back card-body">
                        <div class="text">
                            <p class="-wrapper --wr-fl">
                                <span>
                                    <p>Shipping time frames vary on a case-by-case basis. A good rule of thumb, calculate how many miles your trip is from point A to B and divide that by 300. That is the best estimated number of days it will take for a vehicle to be transported after a driver has picked it up and it is in transit.</p>
                                    <p>Time of year and the weather can also factor into the time frames. Once we have booked a driver for you, we will be able to provide you with the best estimated shipping time frames, based off of his personal itinerary.</p>
                                </span>
                            </p>
                        </div>
                        <div class="logo-wrapper --short-margin">
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq5.svg" alt="">
                        </div>
                    </div>
                </div>
            </li>
        }@else {
            <li [@faqsCard] class="card" id="card-9" (click)="togglFaqs('5')">
                <div class="card-inner" (@faqsToggl.done)="faqsAnimFinish($event)" [@faqsToggl]="{value: openedFaq === '5' ? 'rotated' : 'common',
                params: {
                    cardHeight: minFaqCardH,
                    currHeight: animH || '*',
                    time: animH ? '0.3s' : '0.5s'
                }
                }">
                    <div class="front card-body">
                        <h3 class="top-line">
                            <span class="inner-text">How long does it take to<br> ship a vehicle?</span>
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq5.svg" alt="">
                        </h3>
                        <p class="text">Shipping time frames vary on a case-by-case basis. A good rule of thumb, calculate how many miles your trip..</p>
                    </div>
                    <div  class="back h-fit card-body">
                        <div data-back class="double text"  [ngClass]="{
                            '--min-text': minText
                        }">
                            <p class="wrapper --wr-fl">
                                <span>
                                    Shipping time frames vary on a case-by-case basis. A good rule of thumb, calculate how many miles your trip is from point A to B and divide that by 300. That is the best estimated number of days it will take for a vehicle to be transported after a driver has picked it up and it is in transit.
                                    <br>
                                    <br>
                                    Time of year and the weather can also factor into the time frames. Once we have booked a driver for you, we will be able to provide you with the best estimated shipping time frames, based off of his personal itinerary.
                                </span>
                            </p>
                        </div>
                        <div class="logo-wrapper">
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq5.svg" alt="">
                        </div>
                    </div>
                </div>
                
            </li>
        }
        
    }
    @case (6) {
        @if(['desktop','macxl'].includes(type)){
            <li [@faqsCard] class="card" id="card-10" (click)="togglFaqs('6')">
                <div class="card-inner" (@faqsToggl.done)="faqsAnimFinish($event)" [@faqsToggl]="openedFaq === '6' ? 'rotated' : 'common'">
                    <div class="front card-body">
                        <h3 class="top-line">
                            <span class="inner-text">How much gas should i have in<br> my vehicle during transport?</span>
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq6.svg" alt="">
                        </h3>
                        <p class="text">When shipping a vehicle in the lower 48 states of the USA. There is no limit of how much gas you should or should not have...<</p>
                    </div>
                    <div class="back card-body">
                        <div class="text">
                            <p class="-wrapper --wr-fl">
                                <span>
                                    <p>When shipping a vehicle in the lower 48 states of the USA. There is no limit of how much gas you should or should not have. As long as there is enough to start the vehicle a couple times and get it on and off a truck, you are good to go.</p>
                                    <p>Full tanks of gas are okay too! If you are shipping your vehicle to Alaska, Hawaii or Internationally, then you are only allowed ¼ tank of gas or less.</p>
                                </span>
                            </p>
                        </div>
                        <div class="logo-wrapper --short-margin">
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq6.svg" alt="">
                        </div>
                    </div>
                </div>
            </li>
        }@else {
            <li [@faqsCard] class="card" id="card-10" (click)="togglFaqs('6')">
                <div class="card-inner" (@faqsToggl.done)="faqsAnimFinish($event)" [@faqsToggl]="{value: openedFaq === '6' ? 'rotated' : 'common',
                params: {
                    cardHeight: minFaqCardH,
                    currHeight: animH || '*',
                    time: animH ? '0.3s' : '0.5s'
                }
                }">
                    <div class="front card-body">
                        <h3 class="top-line">
                            <span class="inner-text">How much gas should i have in<br> my vehicle during transport?</span>
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq6.svg" alt="">
                        </h3>
                        <p class="text">When shipping a vehicle in the lower 48 states of the USA. There is no limit of how much gas you should or should not have...</p>
                    </div>
                    <div  class="back h-fit card-body">
                        <div data-back class="double text"  [ngClass]="{
                            '--min-text': minText
                        }">
                            <p class="wrapper --wr-fl">
                                <span>
                                    When shipping a vehicle in the lower 48 states of the USA. There is no limit of how much gas you should or should not have. As long as there is enough to start the vehicle a couple times and get it on and off a truck, you are good to go.
                                    <br>
                                    <br>
                                    Full tanks of gas are okay too! If you are shipping your vehicle to Alaska, Hawaii or Internationally, then you are only allowed ¼ tank of gas or less.
                                </span>
                            </p>
                        </div>
                        <div class="logo-wrapper">
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq6.svg" alt="">
                        </div>
                    </div>
                </div>
                
            </li>
        }
        
    }
    @case (7) {
        @if(['desktop','macxl'].includes(type)){
            <li [@faqsCard] class="card" id="card-10" (click)="togglFaqs('7')">
                <div class="card-inner" (@faqsToggl.done)="faqsAnimFinish($event)" [@faqsToggl]="openedFaq === '7' ? 'rotated' : 'common'">
                    <div class="front card-body">
                        <h3 class="top-line">
                            <span class="inner-text">How soon should i book a reservation to@if(type==='tabletxl'){<br>} ship my vehicle?</span>
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq7.svg" alt="">
                        </h3>
                        <p class="text">We recommend making a reservation 7-10 days before your first available date of pickup for the most accurate..</p>
                    </div>
                    <div class="back card-body">
                        <div class="text">
                            <p class="-wrapper --wr-fl">
                                <span>
                                    We recommend making a reservation 7-10 days before your first available date of pickup for the most accurate pricing and timelines. Any sooner than that, it will need to be requoted for the current market rates.
                                </span>
                            </p>
                        </div>
                        <div class="logo-wrapper --short-margin">
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq7.svg" alt="">
                        </div>
                    </div>
                </div>
            </li>
        }@else {
            <li [@faqsCard] class="card" id="card-10" (click)="togglFaqs('7')">
                <div class="card-inner" (@faqsToggl.done)="faqsAnimFinish($event)" [@faqsToggl]="{value: openedFaq === '7' ? 'rotated' : 'common'}">
                    <div class="front card-body">
                        <h3 class="top-line">
                            <span class="inner-text">How soon should i book a reservation to@if(type==='tabletxl'){<br>} ship my vehicle?</span>
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq7.svg" alt="">
                        </h3>
                        <p class="text">We recommend making a reservation 7-10 days before your first available date of pickup for the most accurate..</p>
                    </div>
                    <div  class="back h-fit card-body">
                        <div class="text">
                            <p class="-wrapper --wr-fl">
                                <span>
                                    We recommend making a reservation 7-10 days before your first available date of pickup for the most accurate pricing and timelines. Any sooner than that, it will need to be requoted for the current market rates.
                                </span>
                            </p>
                        </div>
                        <div class="logo-wrapper --short-margin">
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq7.svg" alt="">
                        </div>
                    </div>
                </div>
                
            </li>
        }
        
    }
    @case (8) {
        @if(['desktop','macxl'].includes(type)){
            <li [@faqsCard] class="card" id="card-11" (click)="togglFaqs('8')">
                <div class="card-inner" (@faqsToggl.done)="faqsAnimFinish($event)" [@faqsToggl]="openedFaq === '8' ? 'rotated' : 'common'">
                    <div class="front card-body">
                        <h3 class="top-line">
                            <span class="inner-text">Does my vehicle need to be registered/<br>plated and insured to ship?</span>
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq8.svg" alt="">
                        </h3>
                        <p class="text">No, is the short answer. However, if your location is not accessible by a 10-car hauler, you might want to get...</p>
                    </div>
                    <div class="back card-body">
                        <div class="text">
                            <p class="-wrapper --wr-fl">
                                <span>
                                    <p>No, is the short answer. However, if your location is not accessible by a 10-car hauler, you might want to get a temporary tag to drive it from the meet spot to your location.</p>
                                    <p>That is up to you, but it is not required for any vehicle to be licensed and registered to ship within the United States.</p>
                                </span>
                            </p>
                        </div>
                        <div class="logo-wrapper --short-margin">
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq8.svg" alt="">
                        </div>
                    </div>
                </div>
            </li>
        }@else {
            <li [@faqsCard] class="card" id="card-11" (click)="togglFaqs('8')">
                <div class="card-inner" (@faqsToggl.done)="faqsAnimFinish($event)" [@faqsToggl]="{value: openedFaq === '8' ? 'rotated' : 'common',
                params: {
                    cardHeight: minFaqCardH,
                    currHeight: animH || '*',
                    time: animH ? '0.3s' : '0.5s'
                }
                }">
                    <div class="front card-body">
                        <h3 class="top-line">
                            <span class="inner-text">Does my vehicle need to be registered/<br>plated and insured to ship?</span>
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq8.svg" alt="">
                        </h3>
                        <p class="text">No, is the short answer. However, if your location is not accessible by a 10-car hauler, you might want to get...</p>
                    </div>
                    <div  class="back h-fit card-body">
                        <div data-back class="double text"  [ngClass]="{
                            '--min-text': minText
                        }">
                            <p class="wrapper --wr-fl">
                                <span>
                                    No, is the short answer. However, if your location is not accessible by a 10-car hauler, you might want to get a temporary tag to drive it from the meet spot to your location. 
                                    <br>
                                    <br>
                                    That is up to you, but it is not required for any vehicle to be licensed and registered to ship within the United States.
                                </span>
                            </p>
                        </div>
                        <div class="logo-wrapper">
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq8.svg" alt="">
                        </div>
                    </div>
                </div>
                
            </li>
        }
        
    }
    @case (9) {
        @if(['desktop','macxl'].includes(type)){
            <li [@faqsCard] class="card" id="card-12" (click)="togglFaqs('9')">
                <div class="card-inner" (@faqsToggl.done)="faqsAnimFinish($event)" [@faqsToggl]="openedFaq === '9' ? 'rotated' : 'common'">
                    <div class="front card-body">
                        <h3 class="top-line">
                            <span class="inner-text">Is my vehicle insured<br> during transport?</span>
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq9.svg" alt="">
                        </h3>
                        <p class="text">The driver`s insurance will cover any damage that may happen to the vehicle during transport that is due to his...</p>
                    </div>
                    <div class="back card-body">
                        <div class="text">
                            <p class="-wrapper --wr-fl">
                                <span>
                                    <p>The driver`s insurance will cover any damage that may happen to the vehicle during transport that is due to his negligence. You might want to have your own insurance on your vehicle as well for small things like rock chips or acts of nature.</p>
                                    <p>These are things that are not covered by the driver`s insurance. If you don`t want to insure it and you do not want to worry about rock chips, Enclosed shipping would be the way to go!</p>
                                </span>
                            </p>
                        </div>
                        <div class="logo-wrapper --short-margin">
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq9.svg" alt="">
                        </div>
                    </div>
                </div>
            </li>
        }@else {
            <li [@faqsCard] class="card" id="card-12" (click)="togglFaqs('9')">
                <div class="card-inner" (@faqsToggl.done)="faqsAnimFinish($event)" [@faqsToggl]="{value: openedFaq === '9' ? 'rotated' : 'common',
                params: {
                    cardHeight: minFaqCardH,
                    currHeight: animH || '*',
                    time: animH ? '0.3s' : '0.5s'
                }
                }">
                    <div class="front card-body">
                        <h3 class="top-line">
                            <span class="inner-text">Is my vehicle insured<br> during transport?</span>
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq9.svg" alt="">
                        </h3>
                        <p class="text">The driver`s insurance will cover any damage that may happen to the vehicle during transport that is due to his...</p>
                    </div>
                    <div  class="back h-fit card-body">
                        <div data-back class="double text"  [ngClass]="{
                            '--min-text': minText
                        }">
                            <p class="wrapper --wr-fl">
                                <span>
                                    The driver`s insurance will cover any damage that may happen to the vehicle during transport that is due to his negligence. You might want to have your own insurance on your vehicle as well for small things like rock chips or acts of nature. 
                                    <br>
                                    <br>
                                    These are things that are not covered by the driver`s insurance. If you don`t want to insure it and you do not want to worry about rock chips, Enclosed shipping would be the way to go! 
                                </span>
                            </p>
                        </div>
                        <div class="logo-wrapper">
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq9.svg" alt="">
                        </div>
                    </div>
                </div>
                
            </li>
        }
        
    }
    @case (10) {
        @if(['desktop','macxl'].includes(type)){
            <li [@faqsCard] class="card" id="card-2" (click)="togglFaqs('10')">
                <div class="card-inner" (@faqsToggl.done)="faqsAnimFinish($event)" [@faqsToggl]="openedFaq === '10' ? 'rotated' : 'common'">
                    <div class="front card-body">
                        <h3 class="top-line">
                            <span class="inner-text">Do you offer<br> door to door service?</span>
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq10.svg" alt="">
                        </h3>
                        <p class="text">It makes everyone`s lives easier, including our drivers.</p>
                    </div>
                    <div class="back card-body">
                        <div class="text">
                            <p class="-wrapper --wr-fl">
                                <span>
                                    <p>We do offer door to door service when possible. It makes everyone`s lives easier, including our drivers. However, there are a lot of neighborhood restrictions.</p>
                                    <p>If you live in an area that has restrictions, low hanging trees, dead end streets or the roads are very congested, then the driver will ask you to meet him at the closest large parking lot to your location.</p>
                                </span>
                            </p>
                        </div>
                        <div class="logo-wrapper --short-margin">
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq10.svg" alt="">
                        </div>
                    </div>
                </div>
            </li>
        }@else {
            <li [@faqsCard] class="card" id="card-2" (click)="togglFaqs('10')">
                <div class="card-inner" (@faqsToggl.done)="faqsAnimFinish($event)" [@faqsToggl]="{value: openedFaq === '10' ? 'rotated' : 'common',
                params: {
                    cardHeight: minFaqCardH,
                    currHeight: animH || '*',
                    time: animH ? '0.3s' : '0.5s'
                }
                }">
                    <div class="front card-body">
                        <h3 class="top-line">
                            <span class="inner-text">Do you offer<br> door to door service?</span>
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq10.svg" alt="">
                        </h3>
                        <p class="text">It makes everyone`s lives easier, including our drivers.</p>
                    </div>
                    <div class="back h-fit card-body">
                        <div data-back class="double text"  [ngClass]="{
                            '--min-text': minText
                        }">
                            <p class="wrapper --wr-fl">
                                <span>
                                    We do offer door to door service when possible. It makes everyone`s lives easier, including our drivers. However, there are a lot of neighborhood restrictions.
                                    <br>
                                    <br>
                                    If you live in an area that has restrictions, low hanging trees, dead end streets or the roads are very congested, then the driver will ask you to meet him at the closest large parking lot to your location. 
                                </span>
                            </p>
                        </div>
                        <div class="logo-wrapper">
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq10.svg" alt="">
                        </div>
                    </div>
                </div>
                
            </li>
        }
        
    }
    @case (11) {
        @if(['desktop','macxl'].includes(type)){
            <li [@faqsCard] class="card" id="card-3" (click)="togglFaqs('11')">
                <div class="card-inner" (@faqsToggl.done)="faqsAnimFinish($event)" [@faqsToggl]="openedFaq === '11' ? 'rotated' : 'common'">
                    <div class="front card-body">
                        <h3 class="top-line">
                            <span class="inner-text">Are you a carrier<br> or a broker?</span>
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq11.svg" alt="">
                        </h3>
                        <p class="text">We're a CARRIER FIRST company (Yes we own our trucks and employ drivers) with intention to move every vehicle on our trucks.</p>
                    </div>
                    <div class="back card-body">
                        <div class="text">
                            <p class="-wrapper --wr-fl">
                                <span>
                                    <p>We're a CARRIER FIRST company (Yes we own our trucks and employ drivers) with intention to move every vehicle on our trucks. With fast growth & increase of customers, we added brokerage arm with over 1200 carriers in our network who are proven, qualified and insured.</p>
                                    <p>We use our network carriers that can deliver on routes that we don't service, or that can pickup & deliver faster then one of our drivers.</p>
                                </span>
                            </p>
                        </div>
                        <div class="logo-wrapper --short-margin">
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq11.svg" alt="">
                        </div>
                    </div>
                </div>
            </li>
        }@else {
            <li [@faqsCard] class="card" id="card-3" (click)="togglFaqs('11')">
                <div class="card-inner" (@faqsToggl.done)="faqsAnimFinish($event)" [@faqsToggl]="{value: openedFaq === '11' ? 'rotated' : 'common',
                params: {
                    cardHeight: minFaqCardH,
                    currHeight: animH || '*',
                    time: animH ? '0.3s' : '0.5s'
                }
                }">
                    <div class="front card-body">
                        <h3 class="top-line">
                            <span class="inner-text">Are you a carrier<br> or a broker?</span>
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq11.svg" alt="">
                        </h3>
                        <p class="text">We're a CARRIER FIRST company (Yes we own our trucks and employ drivers) with intention to move every vehicle on our trucks.</p>
                    </div>
                    <div  class="back h-fit card-body">
                        <div data-back class="double text"  [ngClass]="{
                            '--min-text': minText
                        }">
                            <p class="wrapper --wr-fl">
                                <span>
                                    We're a CARRIER FIRST company (Yes we own our trucks and employ drivers) with intention to move every vehicle on our trucks. With fast growth & increase of customers, we added brokerage arm with over 1200 carriers in our network who are proven, qualified and insured. 
                                    <br>
                                    <br>
                                    We use our network carriers that can deliver on routes that we don't service, or that can pickup & deliver faster then one of our drivers.
                                </span>
                            </p>
                        </div>
                        <div class="logo-wrapper">
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq11.svg" alt="">
                        </div>
                    </div>
                </div>
                
            </li>
        }
        
    }
    @case (12) {
        @if(['desktop','macxl'].includes(type)){
            <li [@faqsCard] class="card" id="card-4" (click)="togglFaqs('12')">
                <div class="card-inner" (@faqsToggl.done)="faqsAnimFinish($event)" [@faqsToggl]="openedFaq === '12' ? 'rotated' : 'common'">
                    <div class="front card-body">
                        <h3 class="top-line">
                            <span class="inner-text">How much does it cost to ship<br> my vehicle?</span>
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq12.svg" alt="">
                        </h3>
                        <p class="text">All these factors weigh in on your price. Give us a call for a free quote today! <a tabindex="-1" href="tel:360-539-8600">360-539-8600</a></p>
                    </div>
                    <div class="back card-body">
                        <div class="text">
                            <p class="-wrapper --wr-fl">
                                <span>
                                    <p>LIt depends on a few factors, are you shipping Open, Enclosed, pickup and delivery locations, the size and weight of your vehicle. Is it in running condition, does it have modifications that change the factory size of the vehicle and time of year.</p>
                                    <p>All these factors weigh in on your price. Give us a call for a free quote today! <a tabindex="-1" href="tel:360-539-8600">360-539-8600</a>
                                </span>
                            </p>
                        </div>
                        <div class="logo-wrapper --short-margin">
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq12.svg" alt="">
                        </div>
                    </div>
                </div>
            </li>
        }@else{
            <li [@faqsCard] class="card" id="card-4" (click)="togglFaqs('12')">
                <div class="card-inner" (@faqsToggl.done)="faqsAnimFinish($event)" [@faqsToggl]="{value: openedFaq === '12' ? 'rotated' : 'common',
                params: {
                    cardHeight: minFaqCardH,
                    currHeight: animH || '*',
                    time: animH ? '0.3s' : '0.5s'
                }
                }">
                    <div class="front card-body">
                        <h3 class="top-line">
                            <span class="inner-text">How much does it cost to ship<br> my vehicle?</span>
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq12.svg" alt="">
                        </h3>
                        <p class="text">All these factors weigh in on your price. Give us a call for a free quote today! 360-539-8600</p>
                    </div>
                    <div  class="back h-fit card-body">
                        <div data-back class="double text"  [ngClass]="{
                            '--min-text': minText
                        }" [ngClass]="{
                            '--min-text': minText
                        }">
                            <p class="wrapper --wr-fl">
                                <span>
                                    LIt depends on a few factors, are you shipping Open, Enclosed, pickup and delivery locations, the size and weight of your vehicle. Is it in running condition, does it have modifications that change the factory size of the vehicle and time of year.
                                    <br>
                                    All these factors weigh in on your price. Give us a call for a free quote today! 360-539-8600
                                </span>
                            </p>
                        </div>
                        <div class="logo-wrapper">
                            <img loading="eager"draggable="false" class="sub-logo" src="../assets/faqs/faq12.svg" alt="">
                        </div>
                    </div>
                </div>
                
            </li>
        }
        
    }
}
