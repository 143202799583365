import { Component, OnInit, AfterViewInit, Renderer2, OnDestroy,ChangeDetectorRef, ViewChild, ElementRef, afterNextRender } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import {Observable, Subject, distinctUntilChanged, takeUntil} from 'rxjs'
import { Router } from '@angular/router'
import { trigger, style, animate, transition, } from '@angular/animations';
import { AppService } from '../services/app.service';
import { SafeResourceUrl,DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'cand-form',
    templateUrl: './cand-form.component.html',
    styleUrls: ['./cand-form.component.scss'],
    animations: [
      trigger(
        'menu',
        [
          transition(
            ':enter',
            [
              style({
                 opacity: 0,
                 transform: 'translateX(100%)'
              }),
              animate(
                '0.5s ease-out',
                style({
                    opacity: 1,
                    transform: 'translateX(0%)'
                })
              )
            ]
          ),
          transition(
            ':leave',
            [
              style({
                opacity: 1,
                transform: 'translateX(0%)'
              }),
              animate(
                '0.5s ease-out',
                style({
                    opacity: 0,
                    transform: 'translateX(100%)'
                })
              )
            ]
          )
        ]),
        trigger(
          'loading',[
            transition(":enter",[
              style({
                opacity: 0
              }),
              animate('0.5s linear',style({
                opacity: 1
              }))
            ]),
            transition(":leave",[
              style({
                opacity: 1
              }),
              animate('0.5s linear',style({
                opacity: 0
              }))
            ])
          ]
        )
    ]
})

export class CandidateComponent implements OnInit,AfterViewInit,OnDestroy{
  @ViewChild('iframeForm', {static: false}) candForm:ElementRef | undefined;
    breakpoint$:Observable<object>
    destroyed$ = new Subject<boolean>();
    type:string;
    host:string;
    link:SafeResourceUrl;
    linkLoaded:boolean = false
    dataUrl:string;
    iframeLoaded: boolean = false

    constructor(
        private renderer: Renderer2,
        private router: Router,
        private hostService: AppService,
        private sanitaze: DomSanitizer,
        private cd:ChangeDetectorRef
    ){}
    ngOnInit() : void{
      this.host = this.hostService.getCandFormHost()
      this.link = this.sanitaze.bypassSecurityTrustResourceUrl(`${this.host}/cand-form?referenceId=9dLOUjoYQNo&sourceUrl=https://dieseldispatch.com/cand-form`)
      this.linkLoaded = true
      
      this.dataUrl = `${this.host}/cand-form/`
      this.renderer.removeClass(document.body,'black-blocked')
      this.cd.detectChanges()
    }

    ngAfterViewInit(): void {
      setTimeout(() => {
        this.candForm ? this.candForm.nativeElement.style = '' : undefined
      },1500)
    }

    iframeLoadedHandle():void{
      this.iframeLoaded = true
    }

    ngOnDestroy(): void {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
}